<template>

	<div v-show="!loaded" class="justify-content-center text-center flex-wrap">
		<ProgressSpinner />
		<h5>Loading Data ...</h5>
	</div>

	<div class="grid" v-show="loaded">
		<div class="col-12 lg:col-6 xl:col-12">
			<PartnerSelect class="mr-3" />
			<Calendar class="mr-3" inputId="basic" placeholder="Filter Date" v-model="selectedDateFilter"
				selectionMode="range" :manualInput="false" dateFormat="dd-mm-yy" :showButtonBar="true" />
			<Dropdown class="mr-3" inputId="gender" v-model="selectedGender" :options="genders"
				optionLabel="name" optionValue="code" placeholder="Filter Gender" />
		</div>
		<!-- <div class="col-12 lg:col-6 xl:col-3">
			<div class="card mb-0">
				<div class="flex justify-content-between mb-3">
					<div>
						<span class="block text-500 font-medium mb-3">No. Users Joined</span>
						<div class="text-900 font-medium text-xl">{{ users_total }}</div>
					</div>
				</div>
				<span class="text-green-500 font-medium">{{ users_weekly }} </span>
				<span class="text-500"> this Week</span>
				<br>
				<span class="text-green-500 font-medium">{{ users_daily }} </span>
				<span class="text-500"> today</span>
			</div>
		</div> -->
		<div class="col-12 lg:col-6 xl:col-3">
			<div class="card mb-0">
				<div class="flex justify-content-between mb-3">
					<div>
						<span class="block text-500 font-medium mb-3">No. Task Completed</span>
						<div class="text-900 font-medium text-xl">{{ overall_total }}</div>
					</div>
				</div>
				<span class="text-green-500 font-medium">{{ weekly_deeds_completed }} </span>
				<span class="text-500"> this Week</span>
				<br>
				<span class="text-green-500 font-medium">{{ daily_deeds_completed }} </span>
				<span class="text-500"> today</span>
			</div>
		</div>
		<div class="col-12 lg:col-6 xl:col-3">
			<div class="card mb-0">
				<div class="flex justify-content-between mb-3">
					<div>
						<span class="block text-500 font-medium mb-3">No. Hours Worked</span>
						<div class="text-900 font-medium text-xl">{{ no_hours_total }}</div>
					</div>
				</div>
				<span class="text-green-500 font-medium">{{ no_hours_weekly }} </span>
				<span class="text-500"> this Week</span>
				<br>
				<span class="text-green-500 font-medium">{{ no_hours_daily }} </span>
				<span class="text-500"> today</span>
			</div>
		</div>
		<div class="col-12 lg:col-6 xl:col-3">
			<div class="card mb-0">
				<div class="flex justify-content-between mb-3">
					<div>
						<span class="block text-500 font-medium mb-3">No. People Impacted</span>
						<div class="text-900 font-medium text-xl">{{ people_impacted_total }}</div>
					</div>
				</div>
				<span class="text-green-500 font-medium">{{ people_impacted_weekly }} </span>
				<span class="text-500"> this Week</span>
				<br>
				<span class="text-green-500 font-medium">{{ people_impacted_daily }} </span>
				<span class="text-500"> today</span>
			</div>
		</div>


		<div class="col-12 xl:col-6">
			<div class="card">
				<div class="flex align-items-center justify-content-between mb-4">
					<h5>Feed</h5>
				</div>
				<!-- <span class="block text-600 font-medium mb-3">TODAY</span> -->
				<ul class="p-0 mx-0 mt-0 mb-4 list-none">
					<ScrollPanel style="width: 100%; height: 400px">
						<li v-for="deed in deeds" :key="deed.id"
							class="flex align-items-center py-2 border-bottom-1 surface-border">
							<div v-if="!deed.owner?.profile_picture"
								class="w-3rem h-3rem flex align-items-center justify-content-center bg-blue-100 border-circle mr-3 flex-shrink-0">
							</div>
							<img class="w-3rem h-3rem flex align-items-center justify-content-center border-circle mr-3 flex-shrink-0"
								v-if="deed.owner?.profile_picture"
								v-bind:src="'https://zltoexchange.s3.amazonaws.com/media/' + deed.owner?.profile_picture">
							<div>
								<span class="text-900 line-height-3">
									<span class="text-yellow-500"> {{ deed.owner?.name }} {{ deed.owner?.surname }}
									</span>
									<span class="text-700"> completed
										<span class="text-blue-500">{{ deed.title }}</span>
									</span>
									<br>
									<span class="text-green-500">+ {{ deed.number_of_hours }} hours </span>
									<br>
									<span class="text-green-500">+ {{ deed.number_of_people }} people</span>
									<br>
									<span class="text-gray-600">{{ timeSince(deed.timestamp) }} </span>
								</span>
							</div>
						</li>
					</ScrollPanel>
				</ul>
			</div>
		</div>

		<div class="col-12 xl:col-6">
			<div class="card">
				<div class="flex align-items-center justify-content-between mb-4">
					<h5>Responses</h5>
				</div>
				<Chart type="pie" :data="chartData" />
			</div>
		</div>

		<div class="col-12 xl:col-12">
			<div class="card">
				<h5>Trends</h5>
				<Chart type="line" :data="basicData" />
				<Dropdown v-model="selectedYear" :options="years" optionLabel="name" placeholder="Select a Year" />
			</div>
		</div>
		<div class="col-12 xl:col-12">
			<div class="card">
				<div id="map" ref="map" style="width: 100%; height: 500px;">
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import EventBus from '@/AppEventBus';
import DeedService from '../service/DeedService';
import { store } from '../store/store.js'
import { DateHelper } from '../util/date_helper.js'
import AuthEventBus from "../common/AuthEventBus";
import PartnerSelect from './PartnerDropdown';

export default {
    components: {
		PartnerSelect
    },
	data() {
		return {
			loaded: false,

			deeds: [],

			people_impacted_total: 0,
			people_impacted_weekly: 0,
			people_impacted_daily: 0,

			no_hours_total: 0,
			no_hours_weekly: 0,
			no_hours_daily: 0,

			users_total: 0,
			users_weekly: 0,
			users_daily: 0,

			weekly_deeds_completed: 0,
			daily_deeds_completed: 0,

			mapMarkers: [],
			heatMapMarkers: [],
			map: null,

			overall_total: 0,

			genderSummary: [],

			chartData: null,
			basicData: null,

			selectedGender: null,
			genders: [{ name: 'male', code: 'male' }, { name: 'female', code: 'female' }, { name: 'other', code: 'other' }],

			opportunities: null,
			selectedOpportunity: null,

			selectedDateFilter: null,


			selectedYear: null,

			years: [
				{name: '2023'},
				{name: '2022'},
				{name: '2021'},
				{name: '2020'},
				{name: '2019'}
			]
		}
	},
	productService: null,
	deedService: null,
	themeChangeListener: null,

	mounted() {
		this.getDeedData(localStorage.getItem('partner'))
		this.getCompletedUsers(localStorage.getItem('partner'))
		this.getTimeData(localStorage.getItem('partner'))
		this.getDeedbyGender(localStorage.getItem('partner'))

		this.themeChangeListener = (event) => {
			if (event.dark)
				this.applyDarkTheme();
			else
				this.applyLightTheme();
		};
		EventBus.on('change-theme', this.themeChangeListener);

		if (this.isDarkTheme()) {
			this.applyDarkTheme();
		}
		else {
			this.applyLightTheme();
		}
	},
	computed: {
		getPartner() {
			return store.partner
		},
		getSelectedDate() {
			return this.selectedDateFilter
		},
		getSelectedGender() {
			console.log("@@@@", this.selectedGender)
			return this.selectedGender
		},
		getSelectedYear(){
			console.log("@@@@ year", this.selectedYear)
			return this.selectedYear
		},
	},

	watch: {
		getPartner(newVal) {
			console.log(`New val${newVal} yay!`)
			this.getDeedData(newVal);
			this.getCompletedUsers(newVal);
			this.getTimeData(newVal)
			this.getDeedbyGender(newVal)
		},
		getSelectedDate(newVal) {
			console.log(newVal)

			if (newVal != null) {
				this.start_date = this.formatDate(newVal[0])
				this.end_date = this.formatDate(newVal[1])

				console.log("@@selectedDateFilter", this.start_date, this.end_date)

				this.getDeedData(localStorage.getItem('partner'), this.start_date, this.end_date, this.selectedGender, null)
				this.getCompletedUsers(localStorage.getItem('partner'), this.start_date, this.end_date, this.selectedGender, null)
				this.getTimeData(localStorage.getItem('partner'), this.start_date, this.end_date, this.selectedGender, null)
				this.getDeedbyGender(localStorage.getItem('partner'), this.start_date, this.end_date, this.selectedGender, null)
			}
			else {
				this.getDeedData(localStorage.getItem('partner'))
				this.getCompletedUsers(localStorage.getItem('partner'))
				this.getTimeData(localStorage.getItem('partner'))
				this.getDeedbyGender(localStorage.getItem('partner'))
			}
		},
		getSelectedGender(newVal) {
			console.log("@gender", newVal)

			if (newVal != null) {
				this.getDeedData(localStorage.getItem('partner'), this.start_date, this.end_date, newVal, null)
				this.getCompletedUsers(localStorage.getItem('partner'), this.start_date, this.end_date, newVal, null)
				this.getTimeData(localStorage.getItem('partner'), this.start_date, this.end_date, newVal, null)
				this.getDeedbyGender(localStorage.getItem('partner'), this.start_date, this.end_date, newVal, null)
			}
			else {
				this.getDeedData(localStorage.getItem('partner'))
				this.getCompletedUsers(localStorage.getItem('partner'))
				this.getTimeData(localStorage.getItem('partner'))
				this.getDeedbyGender(localStorage.getItem('partner'))
			}
		},
		getSelectedYear(newVal) {
			console.log("@year", newVal)

			if (newVal != null) {
				this.getTimeData(localStorage.getItem('partner'), this.start_date, this.end_date, this.selectedGender, this.selectedOpportunity, newVal.name)
			}
			else {
				this.getTimeData(localStorage.getItem('partner'))
			}
		},
	},
	beforeUnmount() {
		EventBus.off('change-theme', this.themeChangeListener);
	},
	created() {
		this.deedService = new DeedService();
	},
	methods: {

		timeSince(date) {

			var formatted_date = new Date(date)

			var seconds = Math.floor(((new Date().getTime() / 1000) - formatted_date.getTime() / 1000))

			var interval = seconds / 31536000;

			if (interval == 1) {
				return Math.floor(interval) + " year ago";
			}
			if (interval > 2) {
				return Math.floor(interval) + " years ago";
			}

			interval = seconds / 2592000;
			if (interval > 1 && interval < 2) {
				return Math.floor(interval) + " month ago";
			}
			if (interval > 2) {
				return Math.floor(interval) + " months ago";
			}

			interval = seconds / 86400;
			if (interval > 1 && interval < 2) {
				return Math.floor(interval) + " day ago";
			}
			if (interval > 2) {
				return Math.floor(interval) + " days ago";
			}

			interval = seconds / 3600;
			if (interval > 1 && interval < 2) {
				return Math.floor(interval) + " hour ago";
			}
			if (interval > 2) {
				return Math.floor(interval) + " hours ago";
			}

			interval = seconds / 60;
			if (interval > 1 && interval < 2) {
				return Math.floor(interval) + " minute ago";
			}
			if (interval > 2) {
				return Math.floor(interval) + " minutes ago";
			}

			return Math.floor(seconds) + " seconds";

		},

		formatDate(date) {
			var d = new Date(date),
				month = '' + (d.getMonth() + 1),
				day = '' + d.getDate(),
				year = d.getFullYear();

			if (month.length < 2) {
				month = '0' + month
			}
			if (day.length < 2) {
				day = '0' + day;
			}
			return [year, month, day].join('-');
		},

		infoDetail(name, title) {
			return `<div class="custom-info-window">
					${name} completed ${title}
					</div>`
		},

		initMap() {
			console.log('refs', this.$refs['map'])

			this.map = new window.google.maps.Map(this.$refs['map'], {
				center: { lat: -28.4792625, lng: 24.6727135 },
				zoom: 5,
			});

			for (var x of this.mapMarkers) {
				const marker = new window.google.maps.Marker({
					position: { lat: x.coords.lat, lng: x.coords.lng },
					map: this.map,
					title: 'user'
				})


				const infowindow = new window.google.maps.InfoWindow({
					content: this.infoDetail(x.user, x.title),
					ariaLabel: "User",
				});

				marker.addListener("click", () => {
					infowindow.open({
						anchor: marker,
						map: this.map
					});
				});
			}

			var heatmap = new window.google.maps.visualization.HeatmapLayer({
				data: this.heatMapMarkers.filter(Boolean)
			});
			heatmap.setMap(this.map);
			heatmap.set("radius", 20);
			heatmap.set("dissipating", true);
			heatmap.set("maxIntensity", 20);
		},

		getDeedData(id, start_date, end_date, gender) {
			this.deedService.getDeeds(id, "selfinitiated", start_date, end_date, gender).then(data => {
				console.log("@@ data", data.data)
				this.deeds = data.data.deeds.slice(0, 50)
				console.log("@@@deeds", this.deeds)

				this.overall_total = data.data.deeds.filter((item) => { return item.status == 2 }).length
				this.people_impacted_total = data.data.deeds.reduce((x, item) => x + item.number_of_people, 0);
				this.no_hours_total = data.data.deeds.reduce((x, item) => x + item.number_of_hours, 0);

				var weekly_deeds = data.data.deeds.filter((item) => {
					var date = new Date(item.timestamp).toLocaleDateString("en-GB")
					return date >= DateHelper.weekly_date().firstday &&
						date <= DateHelper.weekly_date().lastday;
				});

				this.weekly_deeds_completed = weekly_deeds.filter((item) => { return item.status == 2 }).length
				this.people_impacted_weekly = weekly_deeds.reduce((x, item) => x + item.number_of_people, 0);
				this.no_hours_weekly = weekly_deeds.reduce((x, item) => x + item.number_of_hours, 0);

				var daily_deeds = data.data.deeds.filter((item) => {
					var date = new Date(item.timestamp).toLocaleDateString("en-GB")
					return date == DateHelper.today()
				});

				this.daily_deeds_completed = daily_deeds.filter((item) => { return item.status == 2 }).length
				this.people_impacted_daily = daily_deeds.reduce((x, item) => x + item.number_of_people, 0);
				this.no_hours_daily = daily_deeds.reduce((x, item) => x + item.number_of_hours, 0);

				this.users_total = data.data.deeds.length

				var users_weekly = data.data.deeds.filter((item) => {
					var date = new Date(item.timestamp).toLocaleDateString("en-GB")
					return date >= DateHelper.weekly_date().firstday &&
						date <= DateHelper.weekly_date().lastday;
				});

				this.users_weekly = users_weekly.length

				var users_daily = data.data.deeds.filter((item) => {
					var date = new Date(item.timestamp).toLocaleDateString("en-GB")
					return date == DateHelper.today()
				});

				this.users_daily = users_daily.length

				console.log(this.loaded)
			},
				error => {
					this.content =
						(error.response && error.response.data && error.response.data.message) ||
						error.message ||
						error.toString();

					if (error.response && error.response.status === 403) {
						console.log('@@@@, error', error)
						AuthEventBus.dispatch("logout");
					}
				})
		},

		getCompletedUsers(id, start_date, end_date, gender) {
			this.deedService.getCompletedUsers(id, "selfinitiated", start_date, end_date, gender).then(data => {
				console.log("@@ data", data.data)

				// Set null to remove markers
				this.mapMarkers = [];
				this.heatMapMarkers = [];

				for (var x of data.data?.completed_users) {
					if (x.location_coordinates != null) {

						var coords = x.location_coordinates.split(',')
						coords = { 'lat': parseFloat(coords[0]), 'lng': parseFloat((coords[1])) }
						var username = x.name + ' ' + x.surname
						if (!Number.isNaN(coords.lat) || !Number.isNaN(coords.lng)) {
							this.mapMarkers.push({ 'user': username, 'title': x.title, 'coords': coords })
							this.heatMapMarkers.push(new window.google.maps.LatLng(coords.lat, coords.lng),)
						}
					}
				}
				console.log("this.mapMarkers", this.mapMarkers)

				this.initMap();
			},
				error => {
					this.content =
						(error.response && error.response.data && error.response.data.message) ||
						error.message ||
						error.toString();

					if (error.response && error.response.status === 403) {
						console.log('@@@@, error', error)
						AuthEventBus.dispatch("logout");
					}
				})
		},

		getTimeData(id, start_date, end_date, gender, task, year) {
			this.deedService.getTimeData(id, "selfinitiated", start_date, end_date, gender, task, year).then(data => {
				console.log("@@ timedata", data.data.permonth)

				var value_data = []
				var label_data = []

				// const options = { day: 'numeric', month: 'long', year: 'numeric' };

				const month = [" ", "January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

				for (var x of data.data.permonth) {

					var name = month[x.month]

					console.log("@@xx", x)
					value_data.push(x.total)
					label_data.push(name)
				}

				this.basicData = {
					labels: label_data,
					datasets: [
						{
							label: 'Total',
							data: value_data,
							fill: false,
							borderColor: '#42A5F5',
							tension: .4
						}
					]
				}
			},
				error => {
					this.content =
						(error.response && error.response.data && error.response.data.message) ||
						error.message ||
						error.toString();

					if (error.response && error.response.status === 403) {
						console.log('@@@@, error', error)
						AuthEventBus.dispatch("logout");
					}
				})
		},

		getDeedbyGender(id, start_date, end_date, gender) {
			this.deedService.getDeedbyGender(id, "selfinitiated", start_date, end_date, gender).then(data => {
				console.log("@@ gender_summary", data.data.gender_summary)
				this.genderSummary = data.data.gender_summary


				var value_data = []
				var label_data = []


				for (var x of data.data.gender_summary) {

					value_data.push(x.total)
					label_data.push(x.gender)
				}

				this.chartData = {
					labels: label_data,
					datasets: [
						{
							data: value_data,
							backgroundColor: ['#000838', '#FDBE1C', '#00D115', '#22A7F0'],
						}
					]
				},

					this.loaded = true
			},
				error => {
					this.content =
						(error.response && error.response.data && error.response.data.message) ||
						error.message ||
						error.toString();

					if (error.response && error.response.status === 403) {
						console.log('@@@@, error', error)
						AuthEventBus.dispatch("logout");
					}
				})
		},

		isDarkTheme() {
			return this.$appState.darkTheme === true;
		},
		applyLightTheme() {
			this.lineOptions = {
				plugins: {
					legend: {
						labels: {
							color: '#495057'
						}
					}
				},
				scales: {
					x: {
						ticks: {
							color: '#495057'
						},
						grid: {
							color: '#ebedef',
						}
					},
					y: {
						ticks: {
							color: '#495057'
						},
						grid: {
							color: '#ebedef',
						}
					},
				}
			};
		},
		applyDarkTheme() {
			this.lineOptions = {
				plugins: {
					legend: {
						labels: {
							color: '#ebedef'
						}
					}
				},
				scales: {
					x: {
						ticks: {
							color: '#ebedef'
						},
						grid: {
							color: 'rgba(160, 167, 181, .3)',
						}
					},
					y: {
						ticks: {
							color: '#ebedef'
						},
						grid: {
							color: 'rgba(160, 167, 181, .3)',
						}
					},
				}
			};
		}
	}
}
</script>