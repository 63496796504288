import api from './api';

export default class DeedService {
    getDeedSummary(id, type, start_date, end_date, gender) {
        var url = `deeds/summary/${id}?deed_type=${type}`

        console.log("@@@strat date", start_date, end_date)

        if (start_date) {
            url = url + `&start_date=${start_date}`;
        }
        if (end_date) {
            url = url + `&end_date=${end_date}`;
        }

        if (gender) {
            url = url + `?&gender=${gender}`;
        }

        console.log("@@@ url", url)

        return api.get(`${url}`);
    }

    getDeeds(id, type, start_date, end_date, gender, task) {
        var url = `deeds/${id}?offset=10&limit=10&deed_type=${type}`

        if (start_date) {
            url = url + `&start_date=${start_date}`;
        }
        if (end_date) {
            url = url + `&end_date=${end_date}`;
        }

        if (gender) {
            url = url + `?&gender=${gender}`;
        }

        if (task) {
            url = url + `&task=${task}`;
        }

        console.log("@@@ url", url)

        return api.get(`${url}`);
    }
    getOpportunities(id) {
        return api.get(`opportunities/list/${id}`);
    }
    getCompletedUsers(id, type, start_date, end_date, gender, task) {
        var url = `deeds/completedusers/${id}?deed_type=${type}`

        if (start_date) {
            url = url + `&start_date=${start_date}`;
        }
        if (end_date) {
            url = url + `&end_date=${end_date}`;
        }

        if (gender) {
            url = url + `?&gender=${gender}`;
        }

        if (task) {
            url = url + `&task=${task}`;
        }

        console.log("@@@ url", url)

        return api.get(`${url}`);
    }
    getTimeData(id, type, start_date, end_date, gender, task, year = 2023) {
        var url = `deeds/permonth/${id}?deed_type=${type}`

        if (start_date) {
            url = url + `&start_date=${start_date}`;
        }
        if (end_date) {
            url = url + `&end_date=${end_date}`;
        }

        if (gender) {
            url = url + `?&gender=${gender}`;
        }

        if (task) {
            url = url + `&task=${task}`;
        }

        if (year) {
            url = url + `&year=${year}`;
        }

        console.log("@@@ url", url)

        return api.get(`${url}`);
    }
    getDeedbyGender(id, type, start_date, end_date, gender, task) {
        var url = `deeds/by/gender/${id}?deed_type=${type}`

        if (start_date) {
            url = url + `&start_date=${start_date}`;
        }
        if (end_date) {
            url = url + `&end_date=${end_date}`;
        }

        if (gender) {
            url = url + `?&gender=${gender}`;
        }

        if (task) {
            url = url + `&task=${task}`;
        }

        console.log("@@@ url", url)

        return api.get(`${url}`);
    }

    getSkillsCategories(id, type, start_date, end_date, gender) {
        var url = `deeds/skillsandcategories/${id}?deed_type=${type}`

        console.log("@@@strat date", start_date, end_date)

        if (start_date) {
            url = url + `&start_date=${start_date}`;
        }
        if (end_date) {
            url = url + `&end_date=${end_date}`;
        }

        if (gender) {
            url = url + `?&gender=${gender}`;
        }

        console.log("@@@ url", url)

        return api.get(`${url}`);
    }
}
