var current_date = new Date; // get current date


export const DateHelper = {

    weekly_date(){
        var first = current_date.getDate() - current_date.getDay(); // First day is the day of the month - the day of the week
        // var last = first + 6; // last day is the first day + 6
    
        var firstday = new Date(current_date.setDate(first)).toLocaleDateString("en-GB");
        var lastday = new Date(current_date.setDate(first + 6)).toLocaleDateString("en-GB");
        return { 'firstday': firstday, 'lastday': lastday}
    },
    today(){
        return current_date.toLocaleDateString("en-GB");
    }
}

