<template>
    <Dropdown v-model="dropdownValue" optionValue="id" :options="partners" optionLabel="name" placeholder="Select Partner" />
</template>
<script>
import PartnerService from '../service/PartnerService';
import { store } from '../store/store.js'
import AuthEventBus from "../common/AuthEventBus";

export default {
    emits: ['change-theme'],
    data() {
        return {
            dropdownValue: null,
            partnerService: null,
            partners: [],
        }
    },
    watch: {
        dropdownValue: function () {
            console.log("@@@@", this.dropdownValue)
            localStorage.setItem('partner', this.dropdownValue)
            store.partner = this.dropdownValue
        }
    },

    mounted(){
		this.partnerService.getUserPartners().then(data => {
            this.partners = data.data;
        });
        this.dropdownValue = localStorage.getItem("partner")
        console.log(localStorage.getItem("partner"))
        AuthEventBus.on("logout", () => {
            this.logOut();
        });
    },
    beforeUnmount() {
        AuthEventBus.remove("logout");
    },
    created(){
		this.partnerService = new PartnerService();
    }
}
</script>
