import api from './api';

export default class PartnerService {

    getUserPartners() {
        return api.get('partner/user/');
    }
    getPartner(id) {
        return api.get(`partner/${id}`);
    }
    getItemGroupAccounts(id) {
        return api.get(`itemgroupaccounts/${id}`);
    }
    getCompletedUsers(id) {
        return api.get(`itemgroupaccounts/completedusers/${id}`);
    }
    getTimeData(id, year = 2023) {
        var url = `itemgroupaccounts/permonth/${id}`

        if (year) {
            url = url + `?year=${year}`;
        }

        return api.get(`${url}`);
    }
    getItemGroupsbyGender(id) {
        return api.get(`itemgroupaccounts/by/gender/${id}`);
    }

    getItems(id, start_date, end_date, gender) {

        var url = `items/${id}`

        console.log("@@@strat date", start_date, end_date)
        
        if(start_date){
            url = url + `?start_date=${start_date}`;
        }
        if(end_date){
            url = url + `&end_date=${end_date}`;
        }

        if(gender){
            url = url + `?&gender=${gender}`;
        }

        console.log("@@@ url", url)

        return api.get(`${url}`);
    }

    getSROI(id) {
        return api.get(`sroi/${id}`);
    }

    getItemGender(id, start_date, end_date, gender) {

        var url = `items/gender/${id}`

        console.log("@@@strat date", start_date, end_date)
        
        if(start_date){
            url = url + `?start_date=${start_date}`;
        }
        if(end_date){
            url = url + `&end_date=${end_date}`;
        }

        if(gender){
            url = url + `?&gender=${gender}`;
        }

        console.log("@@@ url", url)

        return api.get(`${url}`);
    }
}
